<template>
  <div class="price-container" id="price" ref="richTextContainer">
    <h1 class="title">{{ content.title }}</h1>
    <p
      class="des"
      v-if="content.des"
      v-html="content.des.replace(/\./g, '.<br/>')"
    ></p>
    <div class="select-local" v-if="content.moreLocal || showMoreLocal">
      <div class="local-title">Please select the exchange rate</div>
      <el-select
        v-model="country"
        placeholder="Please select language"
        style="width: 360px"
      >
        <el-option
          v-for="(item, index) in contryList"
          :key="index"
          :label="item.key"
          :value="item.value"
        />
      </el-select>
    </div>

    <div class="price-list-warp" v-if="showLoading && moduleChecked">
      <div class="price-item">
        <div class="package-label">
          <p class="text">{{ $t("price.bestValue") }}</p>
        </div>
        <div class="price-title">{{ $t("price.subscriptionPlan") }}</div>
        <div class="price-check">
          {{
            creditsNum(
              productList.find((res) => res.id === checkedplan),
              "price"
            )
          }}
          <span>/{{ showTag("") }}</span>
        </div>
        <div class="price-check-tips">
          {{ getTips(productList.find((res) => res.id === checkedplan)) }}
        </div>
        <div class="price-item-box">
          <div
            class="price-item-li"
            v-for="item in productList"
            :key="item.id"
            v-show="item.offerType"
            @click="checkedplan = item.id"
          >
            <div class="price-item-li-qouta">
              <el-radio v-model="checkedplan" :label="item.id">
                <div class="radio-show">
                  {{ showqouta(item) }}
                  <!-- <span>{{ item.useTime }}</span> -->
                </div>
              </el-radio>
            </div>
            <div class="money-box money-box-price">
              {{ moneyShow(item) }}
              <!-- <span v-if="item.offerType === 2 && item.lowestPrice > 0">{{
                moneyShow(item, "lowestPrice")
              }}</span> -->
            </div>
            <div class="money-box radio-show blod">
              {{ creditsNum(item, "price") }}
              <span>{{ showTag("/") }}</span>
            </div>
          </div>
        </div>

        <div class="goPay" @click="selectPlay(checkedplan)">
          {{ $t("price.subscribeNow") }}
        </div>
      </div>
      <div class="price-item">
        <div class="price-title">{{ $t("price.oneTitle") }}</div>
        <div class="price-check">
          {{ creditsNum(productList.find((res) => res.id === checkedplay)) }}
          <span>/{{ showTag("") }}</span>
        </div>
        <div class="price-check-tips">
          {{ getTips(productList.find((res) => res.id === checkedplay)) }}
        </div>
        <div class="price-item-box">
          <div
            class="price-item-li"
            v-for="item in productList"
            :key="item.id"
            v-show="!item.offerType"
            @click="checkedplay = item.id"
          >
            <div class="price-item-li-qouta">
              <el-radio v-model="checkedplay" :label="item.id">
                <div class="radio-show">
                  {{ showqouta(item) }}
                  <!-- <span>{{ item.useTime }}</span> -->
                </div>
              </el-radio>
            </div>
            <div class="money-box money-box-price">
              {{ moneyShow(item) }}
              <!-- <span v-if="item.offerType === 2 && item.lowestPrice > 0">{{
                moneyShow(item, "lowestPrice")
              }}</span> -->
            </div>
            <div class="money-box radio-show blod">
              {{ creditsNum(item) }}
              <span>{{ showTag("/") }}</span>
            </div>
          </div>
        </div>
        <div class="goPay" @click="selectPlay(checkedplay)">
          {{ $t("price.buyNew") }}
        </div>
      </div>
    </div>
    <div class="code-txt">
      <div class="title" @click="openModal('open')">
        {{ $t("price.codeTxt") }}
      </div>
      <div class="maks" v-if="isShowCode" @click="openModal('close')">
        <div class="code-inset" @click.stop>
          <input
            type="text"
            placeholder="Enter your discount code"
            v-model="discountCode"
          />
          <div class="message-tips" v-if="codeError">
            {{ $t("price.code") }}
          </div>
          <div class="btn" @click="codeError = true">
            {{ $t("price.checkBtn") }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="price-list"
      v-if="showLoading && !moduleChecked"
      :class="[`price-list-${productList.length}`]"
    >
      <div
        class="price-setup"
        v-for="(item, idx) in priceSetup"
        v-show="productList[idx]"
        :key="idx"
      >
        <div style="display: flex; align-items: center; flex-wrap: wrap">
          <div
            v-if="productList[idx]"
            v-html="
              item.price
                .replace(/{money}/g, moneyShow(productList[idx]))
                .replace(/{currencyUnit}/g, '')
                .replace(/{useTime}/g, productList[idx].useTime)
            "
          ></div>
          <div class="tag">{{ item.tag }}</div>
        </div>
        <ul>
          <li class="des" v-for="line in item.desList" :key="line">
            <span
              v-html="
                line
                  .replace(
                    /{quota}/g,
                    (productList[idx] &&
                      productList[idx] &&
                      productList[idx].config &&
                      productList[idx].config.quota) ||
                      '1800'
                  )
                  .replace(
                    /{useTime}/g,
                    (productList[idx] && productList[idx].useTime) || 'month'
                  )
              "
            ></span>
          </li>
        </ul>
        <button
          :style="`cursor: ${loading ? 'wait' : ''}`"
          v-show="productList.length"
          class="pay-button"
          @click="handlePay(idx)"
        >
          {{ item.buttonTxt }}
        </button>
        <p
          class="tip"
          v-if="productList[idx]"
          v-html="
            item.tip
              .replace(/{money}/g, moneyShow(productList[idx]))
              .replace(/{price}/g, moneyShow(productList[idx], 'price'))
              .replace(/{useTime}/g, productList[idx].useTime)
              .replace(/monthly/g, productList[idx].useTimes ||'monthly')
              .replace(/{useTimes}/g, productList[idx].useTimes || 'monthly')
              .replace(/{currencyUnit}/g, '')
          "
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "./ui/Pop-up.vue";

const currencyMap = {
  RUB: "₽",
  USD: "US$",
  VND: "VND",
  PHP: "₱",
  ZAR: "R",
  GBP: "£",
  SGD: "S$",
  NZD: "NZD$",
  EUR: "€",
  INR: "₹",
  CAD: "C$",
  AUD: "AUD$",
  IDR: "Rp",
  MYR: "RM",
  KRW: "₩",
  HKD: "HK$",
  TWD: "NT$",
  BRL: "R$",
  THB: "฿",
  HKD: "HKD",
  TRY: "₺",
  AED: "د.إ",
};

const offerTypePrices = {
  0: "price",
  1: "tryoutPrice",
  2: "firstDiscountPrice",
};

const durationUnitType = {
  1: "hour",
  2: "day",
  3: "week",
  4: "month",
  5: "year",
  100: "Lifetime",
};
const durationUnitTypes = {
  1: "hourly",
  2: "dayly",
  3: "weekly",
  4: "monthly",
  5: "yearly",
  100: "Lifetime",
};

import { getProductList, createOrder, payOrder } from "@/api/pay";
import { $extractDomain } from "@/utils/index";
export default {
  name: "Banner",
  props: {
    payUrl: {
      type: Object,
      required: true,
      default: () => {},
    },
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
    defaultProductList: {
      type: Array,
      default: () => {
        return [
          {
            money: "49.8",
            price: "49.8",
            useTime: "1-month",
          },
          {
            money: "0.99",
            price: "49.8",
            useTime: "2-days",
          },
        ];
      },
    },
  },
  components: { Popup },
  computed: {
    duration() {
      return this.$t("duration");
    },
    lang() {
      let localLang = localStorage.getItem("lang") || "";
      let lang = this.$route.query.lang || localLang || "en";
      return lang;
    },
    contryList() {
      return [
        { key: "USD", value: "1" },
        { key: "AUD", value: "1.48" },
        { key: "NZD", value: "1.61" },
        { key: "EUR", value: "0.90" },
        { key: "HKD", value: "7.79" },
      ];
    },
    PriceConversion() {
      return {
        VND: 0.000039,
        IDR: 0.000062,
        PHP: 0.01729,
        RUB: 0.0088,
      };
    },
  },
  watch: {
    discountCode: {
      handler() {
        this.codeError = false;
      },
      deep: true,
    },
  },
  data() {
    return {
      codeError: false,
      isShowCode: false,
      discountCode: "",
      showMoreLocal: false,
      country: "1",
      loading: false,
      checkedplan: "",
      checkedplay: "",
      showLoading: false,
      priceSetup: [],
      moduleChecked: false,
      //  审核马甲的订阅/一次性套餐可能顺序不同，按实际文案顺序调整
      productList: [],
      qoutaConfig: {
        1: {
          10: 10,
          20: 11,
          30: 12,
          40: 13,
        },
        2: {
          10: 10,
          20: 11,
          30: 12,
          40: 13,
        },
        3: {
          10: 0.5,
          20: 0.6,
          30: 0.7,
          40: 0.8,
        },
        4: {
          10: 10,
          20: 11,
          30: 12,
          40: 13,
        },
        5: {
          10: 10,
          20: 11,
          30: 12,
          40: 13,
        },
        6: {
          10: 10,
          20: 11,
          30: 12,
          40: 13,
        },
      },
    };
  },
  mounted() {
    // if (this.content.priceModule && this.content.priceModule === "price2") {
    this.moduleChecked = true;
    // }
    this.$nextTick(() => {
      let mainPart = $extractDomain(window.location.hostname);
      let channel =
        this.$route.query.channel || localStorage.getItem("channel");
      if (channel) {
        mainPart = `${mainPart}_${channel}`;
        localStorage.setItem("channel", channel);
      } else {
        let localLang = localStorage.getItem("lang") || "";
        let lang = this.$route.query.lang || localLang || "en";
        // if (lang !== "en") {
        //   mainPart = `${mainPart}_${lang}`;
        // }
      }
      let siteInfo = sessionStorage.getItem("siteInfo");
      if (siteInfo) {
        siteInfo = JSON.parse(siteInfo);
        let localLang = localStorage.getItem("lang") || "";
        let lang = this.$route.query.lang || localLang || "en";
        if (siteInfo.data.common.en) {
          if (lang === "ph") lang = "en";
          siteInfo.data.common = siteInfo.data.common[lang];
        }
        this.showMoreLocal = siteInfo.data?.common?.NavBar?.moreLocal || false;
      }
      getProductList({ code: mainPart, extend: {} })
        .then((result) => {
          const contentPriceList = JSON.parse(
            JSON.stringify(this.content.priceSetup)
          );
          let priceTime = '<span class="medium-bold">/{useTime}</span>';
          this.priceSetup = contentPriceList.map((res) => {
            if (!res.price.includes("{useTime}")) {
              res.price = res.price + priceTime;
            }
            res.tip = res.tip
              .replace(/monthly/g, "{useTimes}")
              .replace(/bulanan/g, "{useTimes}")
              .replace(/setiap bulan/g, "{useTimes}")
              .replace(/ежемесячно/g, "{useTimes}")
              .replace(/еженедельно/g, "{useTimes}")
              .replace(/hàng tháng/g, "{useTimes}")
            return res;
          });
          console.log(this.priceSetup[1]);
          if (result.result && result.result.length) {
            this.productList = result.result.map(this.useFillPriceInfo);
          } else {
            this.productList = this.defaultProductList;
          }
          try {
            let siteInfo = sessionStorage.getItem("siteInfo");
            if (siteInfo) {
              siteInfo = JSON.parse(siteInfo);
            }
            const siteType = Number(siteInfo.data.type);
            const findPrice = (prices, item, subscription = false) => {
              let price = item.price;
              // if (!subscription) {
              //   if (item.offerType === 1) {
              //     price = item.tryoutPrice;
              //   } else if (item.offerType === 2) {
              //     price = item.firstDiscountPrice;
              //   }
              // }
              if (item.currency !== "USD") {
                price = price * this.PriceConversion[item.currency];
              }
              const extractNumber = parseFloat(price);
              let sortedPrices = Object.keys(prices).sort((a, b) => a - b);
              for (let price of sortedPrices) {
                if (extractNumber < price) {
                  let priceNum = prices[price];
                  if (item.offerType === 1) {
                    priceNum = priceNum + priceNum * 0.2;
                  }
                  return Math.ceil(priceNum * Math.ceil(extractNumber));
                }
              }
              let priceNum = prices[sortedPrices[sortedPrices.length - 1]];
              if (item.offerType === 1) {
                priceNum = priceNum + priceNum * 0.2;
              }
              return Math.ceil(priceNum * Math.ceil(extractNumber));
            };
            this.productList = this.productList.map((res) => {
              const qoutaConfig = this.qoutaConfig[siteType];
              let quota = findPrice(qoutaConfig, res);
              let subquota = findPrice(qoutaConfig, res, true);
              res.config = {
                quota: quota,
                subquota,
              };
              return res;
            });
          } catch (error) {
            console.log(error, "123>>>");
          }
          if (this.productList.length > this.priceSetup.length) {
            const priceItems = {
              oneOff: this.priceSetup.find(
                (res) => (res.type || res.buttonTxt) === "Pay"
              ),
              subscription: this.priceSetup.find(
                (res) => (res.type || res.buttonTxt) === "Trial Now"
              ),
            };
            this.priceSetup = this.productList.map((res) => {
              if (res.offerType !== 0) {
                return priceItems.subscription;
              }
              return priceItems.oneOff;
            });
          }
          const planList = this.productList.filter((res) => res.offerType);
          if (planList.length > 0) {
            this.checkedplan = planList[0].id;
          }
          const playList = this.productList.filter((res) => !res.offerType);
          if (playList.length > 0) {
            this.checkedplay = playList[0].id;
          }
          this.$nextTick(() => {
            this.addClickEventToElement();
          });
          //如果需要全部都切换的话，就把这里注释打开，然后把模块的v-if开关打开一下。
          // let module = false;
          // let checkedModule = this.productList.filter(
          //   (res) => res.config?.quota
          // );
          // if (checkedModule.length > 0) {
          //   module = true;
          // }
          // this.moduleChecked = module;
          this.showLoading = true;
        })
        .catch((e) => {
          this.productList = this.defaultProductList;
        });
    });
    if (this.$route.hash) {
      this.$nextTick(() => {
        document.querySelector(this.$route.hash) &&
          document.querySelector(this.$route.hash).scrollIntoView();
      });
    }
  },
  methods: {
    useFillPriceInfo(item) {
      item.money = item[offerTypePrices[item.offerType]];
      let unit = "durationUnit";
      let duration = "duration";
      if (item.offerType === 1) {
        unit = "tryoutDurationUnit";
        duration = "tryoutDuration";
      }
      const durationUnit = item[unit];
      const durationDate = item[duration];

      item.useTime = this.duration[durationUnitType[durationUnit]];
      if (durationDate === 100) {
        item.useTime = this.$t(`duration.LifeTime`);
      } else {
        item.useTime = durationDate + "-" + item.useTime;
      }
      item.useTimes = this.duration[durationUnitTypes[item.durationUnit]];
      if (durationDate === 100) {
        item.useTimes = this.$t(`duration.LifeTime`);
      }
      item.currencyUnit = currencyMap[item.currency];
      if (item.config) {
        try {
          item.config = JSON.parse(item.config);
        } catch (error) {
          item.config = {};
        }
      } else {
        item.config = {};
      }
      return item;
    },
    openModal(type) {
      if (type == "open") {
        this.isShowCode = true;
        this.codeError = false;
        this.discountCode = "";
        this.disableScroll();
        return;
      }
      this.isShowCode = false;
      this.codeError = false;
      this.discountCode = "";
      this.enableScroll();
    },
    disableScroll() {
      document.body.style.overflow = "hidden";
    },
    enableScroll() {
      document.body.style.overflow = "";
    },
    formatPrice(price, currency, locale) {
      // 使用 Intl.NumberFormat 进行格式化
      const formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
      });
      return formatter.format(price);
    },
    showTag(quota) {
      let siteInfo = sessionStorage.getItem("siteInfo");
      if (siteInfo) {
        siteInfo = JSON.parse(siteInfo);
      }
      const siteType = Number(siteInfo.data.type);
      switch (siteType) {
        case 3:
          return this.$t("price.showHours", { quota });
        case 5:
          return this.$t("price.showpdf", { quota });
        default:
          return this.$t("price.showCredit1", { quota });
      }
    },
    showqouta(item, qoutaName = "quota") {
      let siteInfo = sessionStorage.getItem("siteInfo");
      if (siteInfo) {
        siteInfo = JSON.parse(siteInfo);
      }
      const siteType = Number(siteInfo.data.type);
      const showTag = (quota) => {
        switch (siteType) {
          case 3:
            return this.$t("price.showHours", { quota });
          case 5:
            return this.$t("price.showpdf", { quota });
          default:
            return this.$t("price.showCredit", { quota });
        }
      };
      if (item.config[qoutaName]) {
        return showTag(item.config[qoutaName]);
      }
      // const qoutaConfig = this.qoutaConfig[siteType];
      // if (item.offerType===1) {
      //   Object.keys(qoutaConfig).forEach((key) => {
      //     qoutaConfig[key] = qoutaConfig[key] + qoutaConfig[key] * 0.5;
      //   });
      // }
      // return showTag(findPrice(qoutaConfig));
    },
    getTips(item) {
      if (item.offerType === 0) {
        return this.$t("price.tips0", {
          money: this.moneyShow(item),
          price: this.moneyShow(item, "price"),
          useTime: item.useTime.replace(/-/g, " "),
          useTimes: item.useTimes.replace(/-/g, " "),
          currencyUnit: "",
          quota: this.showqouta(item, "quota"),
          subquota: this.showqouta(item, "subquota"),
        });
      }
      if (item.offerType === 1) {
        return this.$t("price.tips", {
          money: this.moneyShow(item),
          price: this.moneyShow(item, "price"),
          useTime: item.useTime.replace(/-/g, " "),
          useTimes: item.useTimes.replace(/-/g, " "),
          currencyUnit: "",
          quota: this.showqouta(item, "quota"),
          subquota: this.showqouta(item, "subquota"),
        });
      }
      if (item.offerType === 2) {
        return this.$t("price.tip1", {
          money: this.moneyShow(item),
          price: this.moneyShow(item, "price"),
          useTime: item.useTime.replace(/-/g, " "),
          useTimes: item.useTimes.replace(/-/g, " "),
          currencyUnit: "",
          quota: this.showqouta(item, "subquota"),
        });
      }
      return "";
    },
    creditsNum(item, type = "money") {
      if (!item) {
        return "--";
      }
      const getonecredits = (money, quota = 0) => {
        if (!quota) {
          return money;
        }
        let num = money / quota > 100 ? 0 : 3;
        return Number(money / quota).toFixed(num);
      };
      if (this.content.moreLocal || this.showMoreLocal) {
        const local = this.contryList.find((res) => res.value === this.country);
        let money = Number(Number(item[type]) * Number(this.country)).toFixed(
          2
        );
        if (local.key === "NZD") {
          money = this.roundNumber(Number(money));
        }
        return `${currencyMap[local.key]} ${getonecredits(
          money,
          item.config?.quota
        )}`;
      }
      return `${item.currencyUnit || "$"} ${getonecredits(
        item[type],
        item.config?.quota
      )}`;
    },
    roundNumber(num) {
      var roundedNum = Math.ceil(num * 100) / 100; // Round the number to two decimal places
      var result = Math.floor(roundedNum); // Get the integer part of the number
      return Number(Math.floor(result) + 0.99).toFixed(2);
    },
    moneyShow(item, type = "money") {
      const local = this.contryList.find((res) => res.value === this.country);
      if (this.content.moreLocal || this.showMoreLocal) {
        let money = Number(Number(item[type]) * Number(this.country)).toFixed(
          2
        );
        if (local.key === "NZD") {
          money = this.roundNumber(Number(money));
        }
        return this.formatPrice(money, item.currency, local.key);
        // return `${currencyMap[local.key]} ${money}`;
      }
      return this.formatPrice(item[type], item.currency, local.key);
      // return `${item.currencyUnit || "$"} ${item[type]}`;
    },
    addClickEventToElement() {
      const localLink =
        this.$refs.richTextContainer.querySelector("#accountLink");
      if (localLink) {
        localLink.addEventListener("click", this.goAccount);
      }
    },
    goAccount() {
      const has = localStorage.getItem("user_email");
      if (!has) {
        this.$store.commit("OPEN_LOGIN", true);
        sessionStorage.setItem("pushSta", 0);
      } else {
        this.$router.push({ path: "/sub/account" });
        this.$router.go(0);
      }
    },
    async handlePay(idx) {
      if (!this.productList[idx].productsPriceList) {
        return this.nopay();
      }
      if (this.loading) return;
      this.loading = true;
      try {
        const params = {
          productsId: this.productList[idx].id,
          productsPriceId: "0",
          successUrl: `${this.$origin("/sub/payresult")}`, //  支付成功url
          cancelUrl: `${this.$origin("/sub/payresult")}`, //  取消支付链接
          userExtend: {},
          transparentExtend: {},
          orderAdInfoExtend: {},
          marketingChannel: "seo",
        };
        const userState = localStorage.getItem("user_email");
        if (userState) {
          params.email = userState;
        }
        const order = await createOrder(params);

        const result = await payOrder({
          orderId: order.obj.orderId,
          payExtend: {},
        });
        this.loading = false;
        window.location.href = result.obj.redirectUrl;
      } catch (e) {
        this.loading = false;
        alert(e.msg || e);
      }
    },
    async selectPlay(id) {
      if (!id) {
        return this.nopay();
      }
      if (this.loading) return;
      this.loading = true;
      try {
        const params = {
          productsId: id,
          productsPriceId: "0",
          successUrl: `${this.$origin("/sub/payresult")}`, //  支付成功url
          cancelUrl: `${this.$origin("/sub/payresult")}`, //  取消支付链接
          userExtend: {},
          transparentExtend: {},
          orderAdInfoExtend: {},
          marketingChannel: "seo",
        };
        const userState = localStorage.getItem("user_email");
        if (userState) {
          params.email = userState;
        }
        const order = await createOrder(params);

        const result = await payOrder({
          orderId: order.obj.orderId,
          payExtend: {},
        });
        this.loading = false;
        window.location.href = result.obj.redirectUrl;
      } catch (e) {
        this.loading = false;
        alert(e.msg || e);
      }
    },
    nopay() {
      alert("Please wait patiently for us to access the payment");
    },
  },
};
</script>
<style lang="scss">
.el-radio__label {
  font-weight: normal !important;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #5d5d5d !important;
}

.el-radio {
  display: flex;
  align-items: center;
}
.el-radio__inner {
  background: none !important;
  border: 2px solid var(--theme-primary-suffix) !important;
  height: 20px;
  width: 20px;
  &::after {
    background-color: var(--theme-primary-suffix) !important;
    height: 10px;
    width: 10px;
  }
}
</style>
<style scoped lang="scss">
@media (min-width: 981px) {
  .price-container {
    width: 1440px;
    margin: 0 auto;
    padding: 50px 0px;
    padding-bottom: 50px;
    text-align: center;

    .title {
      margin-bottom: 60px;
      font-size: 36px;
      font-family: Roboto-Medium, Roboto;
      color: var(--theme-primary-black);
      line-height: 42px;
      text-align: center;
    }
    .select-local {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .local-title {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
        text-align: left;
      }
    }
    .des {
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: var(--theme-primary-black);
      line-height: 24px;
      margin-bottom: 44px;
      padding: 0 0.3rem;
    }

    &::v-deep .high-bold {
      font-size: 24px;
      font-weight: bold;
      color: var(--theme-primary-black);
      line-height: 28px;
    }

    &::v-deep .medium-bold {
      margin-top: 10px;
      font-size: 14px;
      color: var(--theme-primary-tiny);
      line-height: 18px;
    }

    .price-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      text-align: left;
      &.price-list-3 {
        grid-template-columns: repeat(3, 1fr);
        .price-setup {
          min-width: 300px;
        }
      }
      &.price-list-5 {
        grid-template-columns: repeat(3, 1fr);
        .price-setup {
          min-width: 300px;
        }
      }
      &.price-list-4 {
        grid-template-columns: repeat(3, 1fr);
        .price-setup {
          min-width: 300px;
        }
      }
      .price-setup {
        min-width: 500px;
        margin: 0 auto;
        padding: 32px;
        box-sizing: border-box;
        border: 4px solid #f2f8ff;
        max-width: 500px;
        width: 100%;

        .tag {
          margin-left: 8px;
          padding: 2px 10px;
          font-size: 14px;
          font-weight: 400;
          background: var(--theme-primary-price-tag-bg);
          color: var(--theme-primary-price-tag);
          border-radius: 12px;
          line-height: 20px;
        }

        ul {
          margin: 16px 0 24px;

          li {
            margin-bottom: 8px;
          }
        }

        .pay-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 222px;
          height: 48px;
          font-size: 20px;
          font-weight: bold;
          background: var(--theme-primary-suffix);
          color: var(--theme-primary-white);
          border-radius: 30px;
          border: none;
          line-height: 24px;
          outline: none;

          &:hover {
            opacity: 0.8;
          }
        }

        .tip {
          margin-top: 10px;
          font-size: 14px;
          color: var(--theme-primary-tiny);
          line-height: 18px;
        }
      }
    }
  }
  .price-list-warp {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    gap: 30px;
    .price-item {
      flex: 1;
      background: #f3f6f9;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 36px 52px;
      position: relative;
      min-height: 500px;
      text-align: center;
      .package-label {
        background: url(@/assets/images/package-label.png) no-repeat 50%;
        background-size: 100% 100%;
        height: 105px;
        position: absolute;
        right: -9px;
        top: -10px;
        width: 105px;
        .text {
          color: #fff;
          font-family: Manrope-SemiBold, Manrope;
          font-size: 18px;
          font-weight: 600;
          left: 50%;
          position: absolute;
          text-align: center;
          top: 40%;
          transform: translate(-40%, -50%) rotate(45deg);
          white-space: nowrap;
          width: 110px;
        }
      }
      .price-title {
        color: #020b22;
        font-family: Manrope-Bold, Manrope;
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        text-align: center;
      }
      .price-check {
        color: var(--theme-primary-suffix);
        font-family: Manrope-Bold, Manrope;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.2;
        margin-top: 30px;
        position: relative;
        text-align: center;
        span {
          font-size: 14px;
        }
      }
      .price-check-tips {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 49px;
        color: red;
        font-size: 11px;
        padding: 10px 0;
      }
      .price-item-box {
        min-height: 200px;
        max-height: 400px;
        overflow: auto;
      }
      .price-item-li {
        display: flex;
        color: #5d5d5d;
        font-size: 14px;
        width: 100%;
        padding: 8px 0;
        .price-item-li-qouta {
          flex: 2;
          .radio-show {
            span {
              &::before {
                content: "/";
              }
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        .blod {
          font-weight: bold;
          text-align: left;
          color: #000;
        }
        div {
          flex: 1;
          text-align: right;
        }
        .money-box {
          min-width: 150px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 5px;
          color: #5d5d5d;
          &.money-box-price {
            min-width: 120px;
            text-align: left;
            justify-content: left;
            margin-left: 10px;
          }
          // span {
          //   font-size: 10px;
          //   text-decoration: line-through;
          // }
        }
      }
    }
    .goPay {
      background: var(--theme-primary-suffix);
      border-radius: 24px;
      color: var(--theme-primary-white);
      cursor: pointer;
      font-family: Manrope-Bold, Manrope;
      font-size: 16px;
      font-weight: 700;
      height: 40px;
      line-height: 40px;
      margin: 40px auto 0;
      text-align: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      width: 224px;
    }
  }
  .code-txt {
    .title {
      margin-top: 20px;
      font-size: 14px;
      font-weight: 500;
      font-family: Poppins-Medium, Poppins;
      color: #0000ee;
      line-height: 16px;
      cursor: pointer;
    }
    .maks {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    .code-inset {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 688px;
      padding: 38px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 12px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
      z-index: 1;
      transition: height 0.3s ease-in-out;
      .message-tips {
        text-align: left;
        margin-left: 66px;
        margin-top: 2px;
        color: red;
        font-size: 16px;
        line-height: 24px;
      }
      input {
        width: 80%;
        height: 58px;
        font-size: 26px;
        outline: none; /* 移除默认边框 */
        border: 1px solid #ccc; /* 添加自定义边框 */
        color: #020b22; /* 自定义文字颜色 */
        padding: 12px;
        box-sizing: border-box;
        border-radius: 6px;
      }
      input::placeholder {
        color: #999; /* 自定义占位符文本颜色 */
        // font-style: italic; /* 自定义占位符文本样式 */
      }
      .btn {
        max-width: 45%;
        margin: 0 auto;
        padding: 12px 18px;
        color: #fff;
        font-weight: bold;
        background: var(--theme-primary-suffix);
        margin-top: 20px;
        box-sizing: border-box;
        font-size: 18px;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 981px) {
  .price-container {
    width: 100%;
    margin: 1rem auto;
    text-align: center;
    padding: 0.32rem 0;
    box-sizing: border-box;

    .title {
      margin-bottom: 0.6rem;
      font-size: 36px;
      font-family: Roboto-Medium, Roboto;
      color: var(--theme-primary-black);
      line-height: 42px;
      text-align: center;
    }
    .select-local {
      margin-bottom: 0.4rem;
    }
    .des {
      margin-bottom: 44px;
      font-size: 16px;
      font-weight: 400;
      font-family: Roboto-Regular, Roboto;
      color: var(--theme-primary-black);
      line-height: 24px;
    }
    .price-list-warp {
      .price-item {
        flex: 1;
        background: #f3f6f9;
        border-radius: 0.16rem;
        box-sizing: border-box;
        padding: 0.76rem 0.12rem;
        position: relative;
        text-align: center;
        margin-top: 0.4rem;
        .package-label {
          background: url(@/assets/images/package-label-h5.png) no-repeat 50%;
          background-size: 100% 100%;
          height: 2.1rem;
          position: absolute;
          right: 0;
          top: 0;
          width: 2.09rem;
          .text {
            color: #fff;
            font-family: Manrope-SemiBold, Manrope;
            font-size: 0.34rem;
            font-weight: 600;
            left: 50%;
            position: absolute;
            text-align: center;
            top: 40%;
            transform: translate(-40%, -50%) rotate(45deg);
            white-space: nowrap;
            padding: 0 0 0.08rem 0;
          }
        }
        .price-title {
          color: #020b22;
          font-family: Manrope-Bold, Manrope;
          font-size: 0.6rem;
          font-weight: 700;
          line-height: 0.72rem;
          text-align: center;
        }
        .price-check {
          color: var(--theme-primary-suffix);
          font-family: Manrope-Bold, Manrope;
          font-size: 0.6rem;
          font-weight: 700;
          margin-top: 0.3rem;
          position: relative;
          text-align: center;
          span {
            font-size: 0.28rem;
          }
        }
        .price-check-tips {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 0.6rem;
          color: red;
          font-size: 0.22rem;
          margin-top: 0.24rem;
        }
        .price-item-box {
          margin-top: 0.24rem;
          min-height: 2rem;
          padding: 0 0.3rem;
        }
        .price-item-li {
          display: flex;
          color: #5d5d5d;
          font-size: 0.24rem;
          width: 100%;
          font-weight: bold;
          justify-content: space-between;
          padding: 0.16rem 0;
          align-items: center;
          .radio-show {
            display: flex;
            flex-direction: column;
            line-height: 0.4rem;
            text-align: left;
          }
          .blod {
            font-weight: bold;
            color: #000;
          }
          div {
            display: flex;
          }
          .money-box {
            text-align: right;
            color: #5d5d5d;
            padding-right: 0.2rem;
          }
        }
      }
      .goPay {
        background: var(--theme-primary-suffix);
        border-radius: 0.48rem;
        color: var(--theme-primary-white);
        cursor: pointer;
        font-family: Manrope-Bold, Manrope;
        font-size: 0.32rem;
        font-weight: 700;
        height: 0.8rem;
        line-height: 0.8rem;
        margin: 0.8rem auto 0;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        width: 80%;
      }
    }
    &::v-deep .high-bold {
      font-size: 24px;
      font-weight: bold;
      color: var(--theme-primary-black);
      line-height: 28px;
    }

    &::v-deep .medium-bold {
      margin-top: 10px;
      font-size: 14px;
      color: var(--theme-primary-tiny);
      line-height: 18px;
    }

    .price-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      text-align: left;

      .price-setup {
        min-width: 100%;
        margin: 0 auto;
        padding: 32px;
        box-sizing: border-box;
        border: 4px solid #f2f8ff;

        .tag {
          margin-left: 8px;
          padding: 2px 10px;
          font-size: 14px;
          font-weight: 400;
          background: var(--theme-primary-price-tag-bg);
          color: var(--theme-primary-price-tag);
          border-radius: 12px;
          line-height: 20px;
        }

        ul {
          margin-top: 16px;
          margin-bottom: 24px;

          li {
            margin-bottom: 8px;
          }
        }

        .pay-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 222px;
          height: 48px;
          font-size: 20px;
          font-weight: bold;
          background: var(--theme-primary-suffix);
          color: var(--theme-primary-title);
          line-height: 24px;
          border-radius: 30px;
          border: none;
          outline: none !important;
        }

        .tip {
          margin-top: 10px;
          font-size: 14px;
          color: var(--theme-primary-tiny);
          line-height: 18px;
        }
      }
    }
    .code-txt {
      .title {
        margin-top: 0.2rem;
        font-size: 0.26rem;
        font-weight: 500;
        font-family: Poppins-Medium, Poppins;
        color: #0000ee;
        line-height: 0.32rem;
        cursor: pointer;
      }
      .maks {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      .code-inset {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%;
        // height: 3rem;
        box-sizing: border-box;
        background: #fff;
        border-radius: 0.12rem;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
        z-index: 1;
        .message-tips {
          text-align: left;
          margin-left: 0.8rem;
          margin-top: 2px;
          color: red;
          font-size: 0.24rem;
          line-height: normal;
          white-space: nowrap;
        }
        input {
          width: 80%;
          height: 1rem;
          font-size: 0.26rem;
          outline: none; /* 移除默认边框 */
          border: 1px solid #ccc; /* 添加自定义边框 */
          color: #020b22; /* 自定义文字颜色 */
          padding: 0.24rem;
          margin-top: 0.24rem;
          box-sizing: border-box;
          border-radius: 6px;
        }
        input::placeholder {
          color: #999; /* 自定义占位符文本颜色 */
          // font-style: italic; /* 自定义占位符文本样式 */
        }
        .btn {
          max-width: 45%;
          padding: 0.12rem 0.18rem;
          color: #fff;
          font-weight: bold;
          background: var(--theme-primary-suffix);
          box-sizing: border-box;
          font-size: 0.24rem;
          border-radius: 0.16rem;
          line-height: 0.56rem;
          margin: 0.24rem auto;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
